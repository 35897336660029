import React, { useState, useContext, useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import openSocket from 'socket.io-client'
import 'react-image-gallery/styles/css/image-gallery.css'
import './ProductView.css'
import { Breadcrumbs, Drawer, Typography } from '@material-ui/core'
import { Link, useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import Loaders from '../../custom/components/molecules/Loaders'
import ProductContext from '../../product/context/product/productContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import {
    dateFormatFront,
    dateTimeFormatFunction,
    asyncForEach,
    scrollTop,
} from '../../product/common/components'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
// import Bidding from '../../components/molecules/Bidding/BiddingItem'
import Timer from '../../product/common/timer'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import ProductView from '../../custom/components/organisms/ProductView'
import AuctionContext from '../../product/context/auction/auctionContext'
import BuynowContext from '../../product/context/buynow/buynowContext'
import { Button } from '@material-ui/core'
import { DirectAPICAll } from '../../product/common/components'
import { messageHandler } from '../../product/common/socketHandler'
import AlertContext from '../../product/context/alert/alertContext'
import AuthContext from '../../product/context/auth/authContext'
import CustomSelect from '../../product/components/atoms/Inputs/CustomSelect'
import CustomMultiSelect from '../../product/components/atoms/Inputs/CustomMultiSelect'
import { socket, reInitializeSocket } from '../../product/common/socket'
import ReactPlayer from 'react-player/lazy'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FilterPanel from '../../custom/components/organisms/FilterPanel'
import DirectFilterPanel from '../../custom/components/organisms/DirectFilterPanel'
import CustomDialog from '../../custom/components/organisms/Dialog'
import { CSVLink, CSVDownload } from 'react-csv'
import Popup from '../../custom/components/organisms/Popup'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { Formik, useFormik, Form, Field, FieldArray, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { CSVReader } from 'react-papaparse'
import BuyerContext from '../../product/context/buyer/buyerContext'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Pagination } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import AvatarImage from '../../custom/components/atoms/AvatarImage'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)
const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px',
    },
}))(MuiAccordionDetails)
function accorianTerms(t) {
    return [
        {
            title: 'Description',
            details: 'Terms and Conditions Details',
        },
    ]
}

function LiveAuction(props) {
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const auctionContext = useContext(AuctionContext)
    const { allCategory, allCondition, allLocations, setLoginModal } = customCommonContext
    const {
        search_allauctions,
        getAllAuctionProducts,
        updateAuctionTerms,
        responseStatus: responseStatusAuction,
    } = auctionContext

    const { getAllSearchProducts, getAllSearchProductsManifest, search_allproducts } = useContext(
        ProductContext,
    )
    const [auctionProducts, setAuctionProducts] = useState([])
    const [pageFilterDisplay, setPageFilterDisplay] = useState()
    const [manifestProducts, setManifestProducts] = useState([])
    const [auctionDetails, setAuctionDetails] = useState({})
    const [auctionImages, setAuctionImages] = useState([])
    const [totalProductRecords, setTotalProductsRecords] = useState(0)
    const [toggleDialog, setToggleDialog] = useState(false)
    const csvRef = useRef()
    const buyerContext = useContext(BuyerContext)
    let { bidConfirm, responseStatus: responseStatusBid } = buyerContext
    const { responseStatus: buynowResponse, clearResponse: clearBuynowResponse } = useContext(
        BuynowContext,
    )

    const [isLoadingAuction, setIsLoadingAuction] = useState(true)
    const [isLoadingProject, setIsLoadingProject] = useState(true)
    const history = useHistory()
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const [viewProduct, setViewProduct] = useState([])
    const [csvData, setCsvData] = useState([])
    const { setAlert } = alertContext
    const { user, isAuthenticated, saveSearch } = authContext
    const [bulkPopup, setBulkPoup] = useState(false)
    const [bidInstruction, setBidInstruction] = useState(false)
    const [TableView, setTableView] = useState(false)
    const lvActnRef = useRef()
    const [searchText, setSearchText] = useState('')
    const [termsExpand, setTermsExpand] = useState(false)
    const [expanded, setExpanded] = useState()
    const [canBid, setCanBid] = useState(true)
    const [limit, setLimit] = useState(36)
    const [screenSize, setScreenSize] = useState(getScreenSize())
    const [itemsPerPage, setItemsPerPage] = useState(getItemsPerPage(screenSize))
    const [customPage, setCustomPage] = useState(1)

    const [errorPopup, setErrorPopup] = useState(false)
    const [biddingErrorMessage, setBiddingErrorMessage] = useState('')

    const validationArray = Yup.object({
        //  uploadbulkdoc: Yup.array().required('required'),
        id: Yup.number(),
        title: Yup.string(),
        amount: Yup.string(),
    })

    function getScreenSize() {
        return window.innerWidth
    }

    // Function to calculate items per page based on screen size
    function getItemsPerPage(screenSize) {
        return screenSize <= 768 ? 12 : 36 // Adjust the breakpoint value (768) as per your requirements
    }

    useEffect(() => {
        if (responseStatusBid) {
            // console.log(`responseStatusBid in LiveAuctionPage `, responseStatusBid)
            if (responseStatusBid.from === 'bidCancel' && responseStatusBid.status === 'success') {
                getAllSearchProducts(
                    { page: customPage, ...formikSearch.values },
                    'refreshproducts',
                )
                // console.log(
                //     `responseStatusBid in LiveAuctionPage formikSearch.values , `,
                //     formikSearch.values,
                // )
            }
        }
    }, [responseStatusBid])

    useEffect(() => {
        // Function to handle screen size changes
        function handleResize() {
            const newScreenSize = getScreenSize()
            if (newScreenSize !== screenSize) {
                setScreenSize(newScreenSize)
                setItemsPerPage(getItemsPerPage(newScreenSize))
            }
        }

        // Attach the event listener for screen size changes
        window.addEventListener('resize', handleResize)

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [screenSize])

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const formikSearch = useFormik({
        initialValues: {
            limit: itemsPerPage,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                searchbar: {
                    value: '',
                    type: 'like',
                    field: 'p.title', //p.desc_proc',
                },
                // searchbarsku: {
                //     value: '',
                //     type: 'like',
                //     field: 'it.sku',
                // },
                subCategory: {
                    value: [],
                    type: 'array',
                    field: 'it.subCategoryTypeId',
                },
                third_sub_category: {
                    value: [],
                    type: 'array',
                    field: 'it.third_sub_categoryID',
                },
                // location: {
                //     value: [],
                //     type: 'array',
                //     field: 'p.location_id',
                // },
                shipType: {
                    value: [],
                    type: 'findinarray',
                    field: 'it.shipTypeId',
                },
                price: {
                    value: 0,
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                auctionid: {
                    value: [],
                    type: 'array',
                    field: 'p.auctionid',
                },
            },
        },
    })

    const formikTerms = useFormik({
        initialValues: {
            auction_id: 0,
            id: 0,
        },
        onSubmit: (values) => {
            updateAuctionTerms({ ...values, terms: 1 })
            changeDialogStatus()
        },
    })

    useEffect(() => {
        if (formikSearch.values.limit !== itemsPerPage) setItemsPerPage(formikSearch.values.limit)
    }, [formikSearch.values.limit])

    useEffect(() => {
        if (props.match.params.aid) {
            formikSearch.setFieldValue(`filters.auctionid.value`, [Number(props.match.params.aid)])
            setIsLoadingAuction(true)

            getAllAuctionProducts(
                {
                    limit: 1,
                    page: 1,
                    orderby: 'ac.date_closed, asc',
                    order: '',
                    market_status: 'open',
                    filters: {
                        auctionid: {
                            value: Number(props.match.params.aid),
                            type: 'in',
                            field: 'ac.id',
                        },
                    },
                },
                'liveauction',
            )
        }
    }, [props.match.params.aid, isAuthenticated])

    useEffect(() => {
        if (formikSearch.values.filters.auctionid.value.length) {
            setIsLoadingProject(true)
            setCustomPage(1)
            getAllSearchProducts({ page: 1, ...formikSearch.values }, 'searchproducts')
        } else {
            formikSearch.setFieldValue(`filters.auctionid.value`, [Number(props.match.params.aid)])
        }
    }, [formikSearch.values])

    useEffect(() => {
        formikSearch.setFieldValue('limit', itemsPerPage)
    }, [itemsPerPage])

    useEffect(() => {
        let images = [
            {
                original: `${global.site_url}/uploads/auction/${auctionDetails.avatar}`,
                thumbnail: `${global.site_url}/uploads/auction/${auctionDetails.avatar}`,
            },
        ]

        setAuctionImages(images)
    }, [auctionDetails])
    // Used to change page
    const onHandlePage = (event, value) => {
        setCustomPage(value)
        getAllSearchProducts({ page: value, ...formikSearch.values }, 'searchproducts')
    }

    const getDownloadManifestData = () => {
        if (manifestProducts && manifestProducts.length) {
            downloadManifest(manifestProducts)
        } else {
            getAllSearchProductsManifest(
                {
                    limit: search_allproducts.totalRecords,
                    page: 1,
                    orderby: 'p.id, desc',
                    order: '',
                    filters: {
                        auctionid: {
                            value: [Number(props.match.params.aid)],
                            type: 'array',
                            field: 'p.auctionid',
                        },
                    },
                },
                'manifestproducts',
            )
        }
    }
    useEffect(() => {
        console.log('search_allproducts', search_allproducts)
        if (
            search_allproducts.from === 'searchproducts' ||
            search_allproducts.from === 'refreshproducts'
        ) {
            setTotalProductsRecords(search_allproducts.totalRecords)
            setAuctionProducts(search_allproducts.records.length ? search_allproducts.records : [])
            setPageFilterDisplay(search_allproducts.setDisp)
            setTimeout(() => {
                scrollTo(0, 0)
                setIsLoadingProject(false)
            }, 1500)
        }
    }, [search_allproducts])

    useEffect(() => {
        if (search_allauctions.from === 'liveauction') {
            if (search_allauctions && search_allauctions.records.length > 0) {
                setAuctionDetails(search_allauctions.records[0])
                formikTerms.setFieldValue('auction_id', search_allauctions.records[0].id)
                formikTerms.setFieldValue('id', search_allauctions.records[0].id)
                setTimeout(() => {
                    setIsLoadingAuction(false)
                }, 1500)
            } else {
                // handleRedirectInternal(history, 'Auction')
            }
        }
    }, [search_allauctions])

    useEffect(() => {
        if (isAuthenticated) {
            if (auctionDetails) {
                if (
                    Object.keys(auctionDetails).length &&
                    auctionDetails?.user_auction_custom_data_terms == null
                ) {
                    setToggleDialog(true)
                } else {
                    setToggleDialog(false)
                }
            }
        } else {
            setToggleDialog(false)
        }
    }, [auctionDetails, isAuthenticated])

    useEffect(() => {
        if (auctionDetails) {
            if (
                auctionDetails.auction_groups != '' &&
                auctionDetails.auction_groups != undefined &&
                auctionDetails.auction_groups != null
            ) {
                const auction_groups = auctionDetails?.auction_groups.split(',')
                if (auction_groups?.length > 0) {
                    if (user?.user_auction_groups?.length > 0) {
                        const temp = auction_groups.find((ele) =>
                            user?.user_auction_groups.includes(parseInt(ele)),
                        )
                        if (temp) {
                            setCanBid(true)
                        } else {
                            setCanBid(false)
                        }
                    } else {
                        setCanBid(false)
                    }
                }
            }
        }
    }, [auctionDetails])
    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }
    // Clears search filter values
    const clearSearchFilter = () => {
        formikSearch.setValues({
            limit: 12,
            orderby: 'p.id, desc',
            order: '',
            filters: {
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                searchbar: {
                    value: '',
                    type: 'like',
                    field: 'p.title', //p.desc_proc',
                },
                subCategory: {
                    value: [],
                    type: 'array',
                    field: 'it.subCategoryTypeId',
                },
                third_sub_category: {
                    value: [],
                    type: 'array',
                    field: 'it.third_sub_categoryID',
                },
                // location: {
                //     value: [],
                //     type: 'array',
                //     field: 'p.location_id',
                // },
                shipType: {
                    value: [],
                    type: 'findinarray',
                    field: 'it.shipTypeId',
                },
                price: {
                    value: 0,
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                auctionid: {
                    value: [],
                    type: 'array',
                    field: 'p.auctionid',
                },
            },
        })
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchTextChange()
        }
    }

    useEffect(() => {
        if (state.right) {
            const index = auctionProducts.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: auctionProducts[index] })
            }
        }
    }, [auctionProducts])

    const handleSearchTextChange = () => {
        formikSearch.setFieldValue(`filters.searchbar.value`, searchText)
        formikSearch.setFieldValue(`skuSearch`, searchText)
        // formikSearch.setFieldValue(`filters.searchbarsku.value`, searchText)
    }

    const viewProductRef = useRef(auctionProducts)
    const userRef = useRef(user)

    useEffect(() => {
        viewProductRef.current = auctionProducts
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setAuctionProducts,
            type,
        )
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (
                data.status === 'failed' &&
                data.error?.indexOf('suspended') !== -1 &&
                userRef?.current.id == data.bpop_cbidder
            ) {
                setErrorPopup(true)
                setBiddingErrorMessage(data.error)
            } else {
                handler(data, 'bidAddtime')
            }
        })
        socket.on('cancelBidEmitted', (data) => {
            formikSearch.values.filters.auctionid.value = [Number(props.match.params.aid)]
            // getAllSearchProducts(formikSearch.values, 'searchproducts')
        })
        socket.on('viewcount', (data) => {
            console.log('viewcount', data)
            handler(data, 'viewcount')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.off('cancelBidEmitted', (data) => {})
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (buynowResponse) {
            if (buynowResponse.from === 'addToCart' && buynowResponse.status === 'error') {
                setErrorPopup(true)
                setBiddingErrorMessage(buynowResponse.message)
            }
            clearBuynowResponse()
        }
    }, [buynowResponse])

    useEffect(() => {
        if (responseStatusAuction) {
            if (responseStatusAuction.from === 'updateAuctionTerms') {
                if (responseStatusAuction.status === 'success') {
                    formikSearch.values.filters.auctionid.value = [Number(props.match.params.aid)]
                    getAllSearchProducts(
                        { page: customPage, ...formikSearch.values },
                        'searchproducts',
                    )
                }
            }
        }
    }, [responseStatusAuction])

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 Per Page',
        },
        {
            value: '24',
            show: 'Results 24 Per Page',
        },
        {
            value: '36',
            show: 'Results 36 Per Page',
        },
        {
            value: '72',
            show: 'Results 72 Per Page',
        },
    ]

    const sortShow = [
        {
            value: 'p.id, desc',
            show: 'None',
        },
        // {
        //     value: 'product_view_counts, desc',
        //     show: 'Most Viewed',
        // },
        // {
        //     value: 'bid_count, desc',
        //     show: 'Most Bids',
        // },
        {
            value: 'p.wprice, asc',
            show: 'Current Price Low to High',
        },
        {
            value: 'p.wprice, desc',
            show: 'Current Price High to Low',
        },
        {
            value: 'p.date_closed, asc',
            show: 'Time Remaining',
        },
        {
            value: 1,
            show: 'Ending Today',
        },
    ]

    useEffect(() => {
        if (search_allproducts.from === 'manifestproducts') {
            const tempProducts = search_allproducts.records.length ? search_allproducts.records : []
            setManifestProducts(tempProducts)
            setTimeout(() => {
                downloadManifest(tempProducts)
            }, 500)
        }

        return () => {
            setManifestProducts([])
        }
    }, [search_allproducts])

    const downloadManifest = async (products, event, done) => {
        let csv = []
        await asyncForEach(products, (data, index) => {
            if (data.auction) {
                let csvdata = {}
                csvdata['Lots#'] = data.id
                csvdata['Title'] = data.title
                allCategory.filter((val, i) => {
                    if (val.id == data.categoryTypeId) {
                        csvdata['Category'] = val.description
                    }
                })
                if (!csvdata['Category']) {
                    csvdata['Category'] = ''
                }
                csvdata['Auction Name'] = data.auctionlot_title
                csvdata['bid_type'] = data?.bid_type || data.custom_auctionlot_bid_type
                csvdata['lot'] = data.lot
                csvdata['sku'] = data.sku
                const conditionType = allCondition.filter((k) => k.id == data.conditionTypeId)
                csvdata['Condition Type'] =
                    conditionType.length > 0 ? conditionType[0].description : ''
                csvdata['Condition Notes'] = data.notes
                csvdata['upc'] = data.upc
                csvdata['model'] = data.model
                csvdata['part_no'] = data.part_no
                csvdata['min_start_bid'] = data.sprice
                csvdata['qty'] = data.qty
                csvdata['Your Max Proxy/Hard Bid'] = ''
                csv.push(csvdata)
            }
        })

        if (csv.length) {
            setCsvData(csv)
            // done(true)
            csvRef.current.link.click()
            return true
        } else {
            setAlert('No item to bid', 'error')
            // done(false)
            return false
        }
    }

    const [jsonCSV, setJsonCSV] = useState([])
    const [csvValues, setCSV] = useState({
        bidCSV: [
            { id: 0, title: 'Test Title', amount: 1000, upc: '', model: '', part_no: '', qty: 1 },
        ],
    })

    const csvValidation = Yup.object({
        bidCSV: Yup.array().of(
            Yup.object().shape({
                id: Yup.number()
                    .typeError('Enter valid id')
                    .min(1, 'Enter valid id')
                    .required('Id required!'),
                amount: Yup.number()
                    .typeError('Only enter numerical values without any special character')
                    .min(0.001, 'Min value is 0.001')
                    .required('Amount required!'),
            }),
        ),
    })

    const formik = useFormik({
        initialValues: csvValues,
        validationSchema: validationArray,
        onSubmit: (values) => {},
        enableReinitialize: true,
    })
    const [uploadbulkform, setUploadbulkform] = useState([
        {
            label: 'ID',
            class: 'col-md-12',
            visible: false,
            name: 'header',
            formik: formik,
        },
    ])

    const initialValues = () => {
        let finaRes = {}
        let initVal = []
        for (var x = 0; x < jsonCSV.length; x++) {
            let csd = {}
            csd.id = jsonCSV[x].id
            csd.title = jsonCSV[x].title
            csd.amount = jsonCSV[x].amount
            csd.min_start_bid = jsonCSV[x].min_start_bid
            csd.lot = jsonCSV[x].lot
            csd.qty = jsonCSV[x].qty
            csd.upc = jsonCSV[x].upc
            csd.model = jsonCSV[x].model
            csd.part_no = jsonCSV[x].part_no
            csd.category = jsonCSV[x].category
            initVal.push(csd)
            console.log(csd, jsonCSV[x], 'csd!!')
        }
        finaRes.bidCSV = initVal

        setCSV(finaRes)
    }

    useEffect(() => {
        initialValues()
    }, [jsonCSV])

    const handleOnDrop = (data) => {
        let jsoncsv = []
        for (var i = 1; i < data.length; i++) {
            console.log(data[i], 'data[i]')
            let ncsv = {
                id: data[i]['data'][0],
                title: data[i]['data'][1],
                category: data[i]['data'][2],
                lot: data[i]['data'][5],
                min_start_bid: data[i]['data'][12],
                amount: data[i]['data'][14],
                notes: data[i]['data'][8],
                upc: data[i]['data'][9],
                model: data[i]['data'][10],
                part_no: data[i]['data'][11],
                qty: data[i]['data'][13],
            }
            console.log(ncsv, data[i]['data'][0], 'ncsv')
            if (ncsv.id > 0) jsoncsv.push(ncsv)
        }
        setJsonCSV(jsoncsv)
        // setBulkPoup(true)
        setTableView(true)
    }
    const handleOnRemoveFile = (data) => {
        setUploadbulkform([
            {
                label: 'ID',
                class: 'col-md-12',
                visible: false,
                name: 'ids',
                formik: formik,
            },
        ])
        setTableView(false)
    }

    // const TermsBidding = {
    //     formik: formikTerms,
    //     data: [
    //         {
    //             label: (
    //                 <h6
    //                     dangerouslySetInnerHTML={{
    //                         __html: auctionDetails.terms,
    //                     }}
    //                 ></h6>
    //             ),
    //             name: 'terms',
    //             type: 'checkbox',
    //             placeholder: '',
    //             class: 'col-12 auth-links-wrap',
    //         },
    //     ],
    // }

    const scrollToFunc = (ref) => window.scrollTo(0, ref.current.offsetTop)

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const addDefaultSrc = (e) => {
        e.target.src = '/assets/images/noimage.png'
    }
    return (
        <div className="liveAuction customContainer">
            {isLoadingAuction ? (
                <div className="liveAucLoader customContainer">
                    <Loaders isLoading={isLoadingAuction} name="product_view" loop={1} />
                </div>
            ) : (
                <>
                    <div className="pvTimerCnt">
                        {auctionDetails.market_status === 'open' ? (
                            <div className="pvTimerBox">
                                <span className="material-icons">timer</span>
                                <h4>
                                    <Timer
                                        date_added={auctionDetails.date_added}
                                        date_closed={auctionDetails.date_closed}
                                        withText={1}
                                        endText={
                                            dateTimeFormatFunction(new Date().toISOString()) >
                                            dateTimeFormatFunction(auctionDetails.date_closed)
                                                ? 'Started Closing: '
                                                : 'Starts Closing: '
                                        }
                                        startText={'Starts in' + ':'}
                                        closedText="Started Closing"
                                    ></Timer>
                                </h4>
                            </div>
                        ) : (
                            <div className="pvTimerBox">
                                <span className="material-icons">timer</span>
                                <label>{'Closed On'}</label>
                                <h4>{dateFormatFront(auctionDetails.date_closed)}</h4>
                            </div>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3">
                            <Breadcrumbs aria-label="breadcrumb" className="customBreadcrumbs">
                                <Link to="/auction">{'Search'}</Link>
                                <Typography color="textPrimary">{auctionDetails.title}</Typography>
                            </Breadcrumbs>
                        </div>
                    </div>
                    <div className="row auctionAssetsCnt">
                        <div className="col-lg-4 col-12 liveAucImages">
                            <AvatarImage
                                data={auctionDetails}
                                className="auctionPageImg"
                                folder="auction"
                            />
                            {/* <ImageGallery
                                items={auctionImages}
                                thumbnailPosition="bottom"
                                showNav={false}
                                showBullets={false}
                                showFullscreenButton={true}
                                showPlayButton={false}
                            /> */}
                        </div>

                        <div className="col-lg-5 col-12 text-left actnDtlWrpr">
                            <div className="acContent">
                                <h2 className="actnTtrl mb-4" id="liveauction_title">
                                    {auctionDetails.title}
                                </h2>
                                <div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">today</span>Bidding Starts:
                                    </p>
                                    <span>{dateTimeFormatFunction(auctionDetails.date_added)}</span>
                                </div>
                                <div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">event</span>Bidding Starts
                                        Closing:
                                    </p>
                                    <span>
                                        {new Date().toISOString() < auctionDetails.date_closed
                                            ? dateTimeFormatFunction(auctionDetails.date_closed)
                                            : 'Closing'}
                                    </span>
                                </div>
                                <div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">place</span>
                                        Location:
                                    </p>
                                    {allLocations
                                        .filter((value) => auctionDetails.location_id === value.id)
                                        .map((location) => (
                                            <span key={location.id}>
                                                {location.name} - {location.address},{' '}
                                                {location.city} {location.state} {location.zipCode}
                                            </span>
                                        ))}
                                </div>
                                <div className="acMiscInfo">
                                    <p>
                                        <span className="material-icons">gavel</span>Type:
                                    </p>
                                    <span className="capitalize">{auctionDetails.bid_type}</span>
                                </div>
                                <div className="acMiscInfo">
                                    <p>Total Lots/Items:</p>
                                    {/* console.log(auctionDetails, 'auctionProducts') */}
                                    <span>{auctionDetails?.projects_count}</span>
                                </div>
                                <Accordion
                                    square
                                    expanded={expanded === `panel1`}
                                    key={1}
                                    onChange={handleChange(`panel1`)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1d-content"
                                        id={`panel1d-header`}
                                    >
                                        <h6 className="accTitle">Description</h6>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <h6
                                            dangerouslySetInnerHTML={{
                                                __html: auctionDetails.description,
                                            }}
                                        ></h6>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    square
                                    expanded={expanded === `panel2`}
                                    key={2}
                                    onChange={handleChange(`panel2`)}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2d-content"
                                        id={`panel2d-header`}
                                    >
                                        <h6 className="accTitle">Terms & Conditions</h6>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <h6
                                            dangerouslySetInnerHTML={{
                                                __html: auctionDetails.terms,
                                            }}
                                        ></h6>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            {/*  {Array.isArray(uri) ? (
                                    <>
                                        <div className="col-lg-7 col-12">
                                    <div className="videoAuction">
                                        <div>
                                            <span className="material-icons">personal_video</span>
                                            <h5>{'video_will_be_streamed_soon'}</h5>
                                        </div>
                                    </div>
                                </div> 
                                    </>
                                ) : (
                                    <div className="col-lg-7 col-12">
                                        <div className="videoAuction">
                                            <ReactPlayer
                                                className="react-player"
                                                width="100%"
                                                height="100%"
                                                url={uri}
                                                playing
                                                stopOnUnmount={false}
                                                muted
                                                controls
                                                playsinline
                                                pip
                                            />
                                        </div>
                                    </div>
                                )} */}
                            {/* Mockup shows bidding here is this bidding for the products in the auction lot? */}
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="acActBtn actnTmrDtl">
                                <div className="acTimer">
                                    <h6>
                                        <span className="material-icons">timer</span>
                                    </h6>
                                    <p>
                                        {auctionDetails.date_closed ? (
                                            <>
                                                <Timer
                                                    date_added={auctionDetails.date_added}
                                                    date_closed={auctionDetails.date_closed}
                                                    withText={1}
                                                    endText={
                                                        dateTimeFormatFunction(
                                                            new Date().toISOString(),
                                                        ) >
                                                        dateTimeFormatFunction(
                                                            auctionDetails.date_closed,
                                                        )
                                                            ? 'Started Closing: '
                                                            : 'Starts Closing: '
                                                    }
                                                    startText={'Starts in' + ':'}
                                                    closedText="Started Closing"
                                                ></Timer>
                                            </>
                                        ) : null}
                                    </p>
                                </div>
                                <PrimaryButton
                                    id="scrolltobid_button"
                                    onClick={() => scrollToFunc(lvActnRef)}
                                    label="Scroll to Bid"
                                    btnSize="small"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 my-4">
                        <div className="col-12 upldBlkWrpr">
                            <div className="lftCtnr text-left">
                                <h3>Interested in buying bulk?</h3>
                                <p>
                                    {' '}
                                    <a
                                        className="cursorDecoy"
                                        onClick={() => setBidInstruction(true)}
                                    >
                                        {' '}
                                        View bidding instructions{' '}
                                    </a>{' '}
                                </p>
                            </div>

                            <div className="rtContnr mnfstBtn plnBtn">
                                {auctionDetails.future_active !== 1 &&
                                auctionProducts &&
                                auctionProducts.length ? (
                                    <>
                                        <Button
                                            className="manifestDownload"
                                            onClick={() => getDownloadManifestData()}
                                            id="complete_manifest"
                                        >
                                            <span className="material-icons-outlined">
                                                file_download
                                            </span>
                                            Download complete manifest
                                        </Button>
                                        {/* {manifestProducts.length ? ( */}
                                        <CSVLink
                                            filename={'manifest.csv'}
                                            className="hidden"
                                            asyncOnClick={true}
                                            data={csvData}
                                            ref={csvRef}
                                        ></CSVLink>
                                        {/* ) : null} */}
                                    </>
                                ) : null}

                                {isAuthenticated ? (
                                    <>
                                        {canBid ? (
                                            <Button
                                                className="mnfstBtn upldBdnBtn"
                                                id="upload_bulk_bidding"
                                                onClick={() => setBulkPoup(true)}
                                            >
                                                <img src="/assets/svg/upload.svg" />
                                                Upload bulk bidding file
                                            </Button>
                                        ) : (
                                            <div style={{ color: 'red' }}>
                                                You are not belong to this auction group
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <Button
                                        className="mnfstBtn upldBdnBtn"
                                        onClick={() => setLoginModal(true)}
                                    >
                                        Login to Bid
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <CustomDialog
                        title="Confirm Terms And Conditions"
                        open={toggleDialog}
                        className="ConfirmModal"
                        disableBackdropClick
                        function={changeDialogStatus}
                    >
                        <>
                            <h5>Please confirm if you want to continue with this action</h5>
                            {/* <form>
                        <div className="row mb-4">{Object.values(mapData(TermsBidding))}</div>
                        <div className="actionWrapper">
                            <Button
                                onClick={() => {
                                    setToggleDialog(false)
                                    history.goBack()
                                }}
                            >
                                Decline
                            </Button>
                            <PrimaryButton
                                onClick={formikTerms.handleSubmit}
                                id="confirm_bid_submit"
                                type="button"
                                disabled={formikTerms.isSubmitting}
                                label={'Accept'}
                            />
                        </div>
                    </form> */}

                            {/* <form>
                        <div className="row mb-4">{Object.values(mapData(TermsBidding))}</div>
                        <div className="checkbox-wrapper mb-4">
                            <input
                                type="checkbox"
                                id="terms"
                                name="terms"
                                checked={formikTerms.values.terms}
                                onChange={formikTerms.handleChange}
                            />
                            <label htmlFor="terms">I agree to the Terms & Conditions</label>
                        </div>
                        <div className="actionWrapper">
                            <Button
                                onClick={() => {
                                    setToggleDialog(false)
                                    history.goBack()
                                }}
                            >
                                Decline
                            </Button>
                            <PrimaryButton
                                onClick={formikTerms.handleSubmit}
                                id="confirm_bid_submit"
                                type="button"
                                disabled={formikTerms.isSubmitting}
                                label={'Accept'}
                            />
                        </div>
                    </form> */}

                            <form onSubmit={formikTerms.handleSubmit}>
                                <div className="row mb-4">
                                    <div className="col-12 auth-links-wrap">
                                        <h6
                                            dangerouslySetInnerHTML={{
                                                __html: auctionDetails.terms,
                                            }}
                                        ></h6>
                                    </div>
                                </div>

                                <div
                                    className="actionWrapper"
                                    style={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="checkbox-wrapper">
                                        <input
                                            type="checkbox"
                                            id="terms"
                                            name="terms"
                                            checked={1}
                                        />
                                        <label htmlFor="terms">
                                            I agree to the Terms & Conditions
                                        </label>
                                    </div>
                                    <div className="auctionBtnWrapper">
                                        <Button
                                            onClick={() => {
                                                setToggleDialog(false)
                                                history.goBack()
                                            }}
                                        >
                                            Decline
                                        </Button>
                                        <PrimaryButton
                                            type="submit"
                                            id="confirm_bid_submit"
                                            disabled={formikTerms.isSubmitting}
                                            label={'Accept'}
                                        />
                                    </div>
                                </div>
                            </form>
                        </>
                    </CustomDialog>
                </>
            )}

            {isLoadingProject ? (
                <div className="liveAucLoader grid customContainer">
                    <Loaders name="product_grid_view" isLoading={isLoadingProject} loop={4} />
                </div>
            ) : (
                <>
                    <div className="row" ref={lvActnRef}>
                        <div className="col-12">
                            <h2 className="laTitle my-4 mt-5">Auction Lots/Items</h2>
                        </div>
                        <div className="srcFltrWpr col-12 d-flex align-items-center justify-content-between">
                            <div className="form-inline my-2 my-lg-0 headerSearchForm">
                                <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search NPS Products"
                                    aria-label="Search"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <Button onClick={handleSearchTextChange} className="btn">
                                    <span className="material-icons">search</span>
                                </Button>
                            </div>
                            <div className="searchMiscFilters w-100 mb-5 d-flex justify-content-end align-items-end">
                                {/* <div className="gridListToggle">
                                    <Button
                                        className={auctionView === 'Grid' ? 'active' : ''}
                                        onClick={() => setAuctionView('Grid')}
                                    >
                                        <span className="material-icons">apps</span>
                                    </Button>
                                    <Button
                                        className={auctionView === 'List' ? 'active' : ''}
                                        onClick={() => setAuctionView('List')}
                                    >
                                        <span className="material-icons">view_list</span>
                                    </Button>
                                </div> */}
                                <Button
                                    className="fltrBtn"
                                    onClick={toggleDrawer('left', true, null)}
                                >
                                    Filters <span className="material-icons ml-2">filter_alt</span>
                                </Button>
                                <div className="mr-2">
                                    <CustomSelect
                                        label={'Results per Page'}
                                        name="resultsPerPage"
                                        selectType="noBorder"
                                        // value={formikSearch.values.limit}
                                        value={itemsPerPage}
                                        size="small"
                                        onChange={(event, editor) => {
                                            formikSearch.setFieldValue(`limit`, event.target.value)
                                        }}
                                    >
                                        {pageOptions.map((opt, optindex) => (
                                            <option key={optindex} value={opt.value}>
                                                {opt.show}
                                            </option>
                                        ))}
                                    </CustomSelect>
                                </div>
                                <CustomSelect
                                    label={'Sort by'}
                                    value={formikSearch.values.orderby}
                                    size="small"
                                    selectType="noBorder"
                                    name="orderby"
                                    // shrink={search.orderby !== '' ? true : false}
                                    onChange={(event, editor) => {
                                        formikSearch.setFieldValue(`orderby`, event.target.value)
                                    }}
                                >
                                    {sortShow.map((opt, optindex) => (
                                        <option value={opt.value}>{opt.show}</option>
                                    ))}
                                </CustomSelect>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="searchResults col-md-12 col-12">
                            {auctionProducts &&
                                auctionProducts.map((data, index) => (
                                    <GridView
                                        data={data}
                                        key={index}
                                        favId={`searchProd_${index}`}
                                        drawerHandler={toggleDrawer('right', true, data)}
                                        onClick={() =>
                                            handleRedirectInternal(history, `lot/${data.id}`)
                                        }
                                    />
                                ))}
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper col-md-12">
                            <h6>
                                {'Showing'} {pageFilterDisplay} {'of'} &nbsp;
                                {search_allproducts.totalRecords}
                            </h6>
                            <Pagination
                                count={Math.ceil(totalProductRecords / itemsPerPage)}
                                page={customPage}
                                onChange={onHandlePage}
                                siblingCount={3}
                                showFirstButton
                                showLastButton
                                boundaryCount={2}
                                // onClick={() =>
                                //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                                // }
                            />
                        </div>
                        {/* <div className="col-12"> */}
                        <Drawer
                            className="rightDrawer"
                            anchor={'right'}
                            open={state['right']}
                            onClose={toggleDrawer('right', false)}
                        >
                            <ProductView
                                data={state.data}
                                drawerHandler={toggleDrawer('right', false)}
                                onClick={() => setState(false)}
                            />
                        </Drawer>
                        <Drawer
                            className="fltrLeftDrawer"
                            anchor={'left'}
                            open={state['left']}
                            onClose={toggleDrawer('left', false)}
                        >
                            <DirectFilterPanel
                                formik={formikSearch}
                                clearSearch={clearSearchFilter}
                                from={2}
                            />
                        </Drawer>
                        {/* </div> */}
                    </div>
                </>
            )}

            <Popup
                modaltitle="Upload Bulk Bidding File"
                open={bulkPopup}
                handleClose={() => {
                    setBulkPoup(false)
                    setCSV({ bidCSV: [] })
                }}
            >
                <div className="csvPopWrpr">
                    <div className="csvRdrWrpr">
                        <CSVReader
                            onDrop={handleOnDrop}
                            onError={handleOnError}
                            addRemoveButton
                            onRemoveFile={handleOnRemoveFile}
                        >
                            <span>Drop CSV file here or click to upload.</span>
                        </CSVReader>
                    </div>
                    {TableView ? (
                        <Formik
                            initialValues={csvValues}
                            enableReinitialize={true}
                            validationSchema={csvValidation}
                            onSubmit={async (values) => {
                                await new Promise((r) => setTimeout(r, 500))
                                values.bidCSV.map((csv) => {
                                    let bid = {
                                        id: csv.id,
                                        wsprice: Number(csv.amount),
                                        bidding_type: auctionDetails.bid_type,
                                    }
                                    bidConfirm(bid)
                                })
                                setBulkPoup(false)
                                setCSV({ bidCSV: [] })
                            }}
                        >
                            {({ values }) => (
                                <Form>
                                    <FieldArray name="bidCSV">
                                        {({ insert, remove, push }) => (
                                            <div className="csvTable">
                                                {values.bidCSV.length > 0 && (
                                                    <table className="table mt-4">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    ID
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    Title
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    Category
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    Lot
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    Min Bid
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    Amount
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    Quantity
                                                                </th>
                                                                {/* <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    UPC
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    Model
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="text-left"
                                                                >
                                                                    Part Number
                                                                </th> */}
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values.bidCSV.map((csv, index) => (
                                                                <>
                                                                    <tr>
                                                                        <td scope="row">
                                                                            <Field
                                                                                name={`bidCSV.${index}.id`}
                                                                                placeholder="id"
                                                                                type="text"
                                                                                value={csv.id}
                                                                                disabled={true}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`bidCSV.${index}.id`}
                                                                            >
                                                                                {(msg) => (
                                                                                    <div
                                                                                        style={{
                                                                                            color:
                                                                                                'red',
                                                                                        }}
                                                                                    >
                                                                                        {msg}
                                                                                    </div>
                                                                                )}
                                                                            </ErrorMessage>
                                                                        </td>
                                                                        <td>
                                                                            <Field
                                                                                name={`bidCSV.${index}.title`}
                                                                                placeholder="Title"
                                                                                type="text"
                                                                                value={csv.title}
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Field
                                                                                name={`bidCSV.${index}.category`}
                                                                                placeholder="Category"
                                                                                type="text"
                                                                                value={csv.category}
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Field
                                                                                name={`bidCSV.${index}.lot`}
                                                                                placeholder="lot"
                                                                                type="text"
                                                                                value={csv.lot}
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Field
                                                                                name={`bidCSV.${index}.min_start_bid`}
                                                                                placeholder="Min Bid"
                                                                                type="text"
                                                                                value={
                                                                                    csv.min_start_bid
                                                                                }
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Field
                                                                                name={`bidCSV.${index}.amount`}
                                                                                placeholder="Enter Amount"
                                                                                type="number"
                                                                                value={csv.amount}
                                                                            />
                                                                            <ErrorMessage
                                                                                name={`bidCSV.${index}.amount`}
                                                                            >
                                                                                {(msg) => (
                                                                                    <div
                                                                                        style={{
                                                                                            color:
                                                                                                'red',
                                                                                        }}
                                                                                    >
                                                                                        {msg}
                                                                                    </div>
                                                                                )}
                                                                            </ErrorMessage>
                                                                        </td>
                                                                        <td>
                                                                            <Field
                                                                                name={`bidCSV.${index}.qty`}
                                                                                placeholder="Quantity"
                                                                                type="text"
                                                                                value={csv.qty}
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                        {/* <td>
                                                                            <Field
                                                                                name={`bidCSV.${index}.upc`}
                                                                                placeholder="UPC"
                                                                                type="text"
                                                                                value={csv.upc}
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Field
                                                                                name={`bidCSV.${index}.model`}
                                                                                placeholder="Model"
                                                                                type="text"
                                                                                value={csv.model}
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Field
                                                                                name={`bidCSV.${index}.part_no`}
                                                                                placeholder="Part No."
                                                                                type="text"
                                                                                value={csv.part_no}
                                                                                disabled={true}
                                                                            />
                                                                        </td> */}
                                                                        <td>
                                                                            <IconButton
                                                                                type="button"
                                                                                className="secondary text-danger"
                                                                                onClick={() =>
                                                                                    remove(index)
                                                                                }
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                            {/* <button>X</button> */}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                        )}
                                    </FieldArray>
                                    {csvValues.bidCSV.length > 0 && (
                                        <PrimaryButton type="submit" label="Submit Bid" />
                                    )}

                                    {/* <button type="submit" className="btn btn-primary">
                                    Submit Bid
                                </button> */}
                                </Form>
                            )}
                        </Formik>
                    ) : null}
                </div>
            </Popup>

            <Popup
                modaltitle="Bidding Instructions"
                open={bidInstruction}
                handleClose={() => setBidInstruction(false)}
            >
                <div className="csvPopWrpr">
                    <p>
                        To bulk bid on all the lots of an auction simply click the “Download
                        Complete Manifest” link. This will download a csv list with all the lots of
                        the auction. Open this with any spreadsheet editor and populate the amount
                        column with the amounts you would like to bid. Once completed make sure you
                        hit save then click the “Upload In Bulk” button. Here you will drag and drop
                        the file to the box or click it to upload. Next you will see a review
                        screen, once everything looks good press the “Submit Bid” button. Now all
                        your bids have been entered all at once! Happy Bidding!
                    </p>
                </div>
            </Popup>
            <>
                <Popup open={errorPopup} size="md" handleClose={() => setErrorPopup(false)}>
                    <>
                        <h4 className="suspended-error">
                            {biddingErrorMessage}
                            <br />
                            Please contact customer service
                        </h4>
                    </>
                </Popup>
            </>
        </div>
    )
}

export default LiveAuction
